import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

//@ts-ignore
import Radar from 'radar-sdk-js';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GeofenceController, {
  Props,
  configJSON,
} from "./GeofenceController";

export default class Geofence extends GeofenceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    Radar.initialize(configJSON.radarKey);
  
  Radar.setUserId('foo');

  Radar.trackOnce({
    latitude: 39.2904,
    longitude: -76.6122,
    accuracy: 65
  }, (err: any, result: any) => {
    if (!err) {
      this.setState({log: this.stringify(result)});
    }
  });

  Radar.getLocation((err: any, result: any) => {
    if (!err) {
      this.setState({location: this.stringify(result)});
    }
  });
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            <Box sx={webStyle.logStyle}>
              <InputLabel id="log">
                The geofence log: {this.state.log}{" "}
              </InputLabel>
              <InputLabel id="location">
                The geofence location: {this.state.location}{" "}
              </InputLabel>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  logStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
// Customizable Area End
