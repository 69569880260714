// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import WeatherIntegration from "../../blocks/WeatherIntegration/src/WeatherIntegration";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import OfflineBrowsing from "../../blocks/OfflineBrowsing/src/OfflineBrowsing";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Maps from "../../blocks/maps/src/Maps";
import Download from "../../blocks/Download/src/Download";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ChatBackuprestore from "../../blocks/ChatBackuprestore/src/ChatBackuprestore";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Videos from "../../blocks/videos/src/Videos";
import BudgetingForecasting from "../../blocks/BudgetingForecasting/src/BudgetingForecasting";
import Geofence from "../../blocks/geofence/src/Geofence";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import OfflineWork from "../../blocks/OfflineWork/src/OfflineWork";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ExpenseTracking from "../../blocks/ExpenseTracking/src/ExpenseTracking";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Analytics from "../../blocks/analytics/src/Analytics";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import MapSettings from "../../blocks/MapSettings/src/MapSettings";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import DownloadableProducts2 from "../../blocks/DownloadableProducts2/src/DownloadableProducts2";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Location from "../../blocks/location/src/Location";
import Surveys from "../../blocks/Surveys/src/Surveys";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import LiveChatSummary2 from "../../blocks/LiveChatSummary2/src/LiveChatSummary2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import CfDbSetup from "../../blocks/CfDbSetup/src/CfDbSetup";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import TaskList from "../../blocks/TaskList/src/TaskList";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
WeatherIntegration:{
 component:WeatherIntegration,
path:"/WeatherIntegration"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Maps:{
 component:Maps,
path:"/Maps"},
Download:{
 component:Download,
path:"/Download"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ChatBackuprestore:{
 component:ChatBackuprestore,
path:"/ChatBackuprestore"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Videos:{
 component:Videos,
path:"/Videos"},
BudgetingForecasting:{
 component:BudgetingForecasting,
path:"/BudgetingForecasting"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
OfflineWork:{
 component:OfflineWork,
path:"/OfflineWork"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ExpenseTracking:{
 component:ExpenseTracking,
path:"/ExpenseTracking"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
MapSettings:{
 component:MapSettings,
path:"/MapSettings"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ProjectTaskTracking:{
 component:ProjectTaskTracking,
path:"/ProjectTaskTracking"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
DownloadableProducts2:{
 component:DownloadableProducts2,
path:"/DownloadableProducts2"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Location:{
 component:Location,
path:"/Location"},
Surveys:{
 component:Surveys,
path:"/Surveys"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
LiveChatSummary2:{
 component:LiveChatSummary2,
path:"/LiveChatSummary2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
CfDbSetup:{
 component:CfDbSetup,
path:"/CfDbSetup"},
TaskGroups:{
 component:TaskGroups,
path:"/TaskGroups"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
TaskList:{
 component:TaskList,
path:"/TaskList"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;